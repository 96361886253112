import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import ChainList from "../components/chain-list"

export const Head = ({ location }) => <Seo pathname={location.pathname} />

const Home = ({ data }) => {
  return (
    <Layout home>
      <h1>Brochain</h1>
      <h2>Trusted PoS validator</h2>
      <p>
        Brochain mainly runs PoS validators in the Cosmos ecosystem. We support
        auto compounding for delegators and a range of services for the
        validator and node operator communities.
      </p>
      <h2>Current mainnet chains</h2>
      <ChainList
        chains={data.allChainsJson.edges}
        images={data.allFile.edges}
      />
      <h2>Current testnet chains</h2>
      <ChainList
        chains={data.allTestChainsJson.edges}
        images={data.allFile.edges}
        testnet
      />
      {/**}
      <h2>Upcoming chains</h2>
      <ChainList
        chains={data.allFutureChainsJson.edges}
        images={data.allFile.edges}
        future
      />
      {/**/}
      <h2>Discontinued chains</h2>
      <ChainList
        chains={[...data.allChainsJson.edges, ...data.allTestChainsJson.edges]}
        images={data.allFile.edges}
        discontinued
      />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    allChainsJson {
      edges {
        node {
          id
          about
          explorerUrl
          stakeUrl
          hidden
          disabled
          key
          logo
          name
          site
          services {
            tmVersion
            gitRepo
            binary
            root
            publicRpc
            publicGrpc
            publicRest
            seedNode
            chainId
            denom
            snapshot
            installation {
              genesisUrl
              addrbookUrl
              seeds
              installScript
            }
            stateSync {
              rpc
              peer
            }
            networkMap
          }
        }
      }
    }
    allTestChainsJson {
      edges {
        node {
          id
          about
          explorerUrl
          hidden
          disabled
          key
          logo
          name
          site
          services {
            tmVersion
            gitRepo
            binary
            root
            chainId
            denom
            snapshot
            installation {
              genesisUrl
              addrbookUrl
              seeds
              installScript
            }
            stateSync {
              rpc
              peer
            }
            networkMap
          }
        }
      }
    }
    allFutureChainsJson {
      edges {
        node {
          id
          about
          key
          logo
          name
          site
        }
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`
